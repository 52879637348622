.main {
  padding: 1.2rem 0;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  justify-content: space-between;
}

.main__usp {
  flex-grow: 1;
  flex-basis: calc(30% - 2rem);
  max-width: calc(30% - 2rem);
  padding: 0 1rem;
}

.main__usp > h3 {
  font-size: 2.5rem;
}

.main__usp > p {
  font-size: 1.65rem;
}

@media (max-width: 719px) {
  .main {
    flex-direction: column;
  }

  .main__usp {
    max-width: 100%;
    padding: 0 2rem;
  }
}
